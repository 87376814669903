import { Box, Button, Typography, Modal, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { image_url, post, updateUser } from "../services/endpoints";
import { setCurrentLocation } from "../store/currentlocation";
import { setHomeStateData } from "../store/homes";
import { setInteractionList } from "../store/interactions";
import { setLoading } from "../store/load";
import { getAllNotifications } from "../store/notification";
import { setToInitalPostData } from "../store/post";
import { getAllTeamThunk, setSelectedTeam } from "../store/team";
import { pxToRem } from "../theme/typography";
import { getFirebaseToken } from "../utils/firebaseInit";
import { getAllTeamIds } from "../store/teamIds";
import moment from "moment";
import showToast from "../utils/showToast";
import DeleteModal from "../components/DeleteModal";
import GroupCard from "../components/Home/GroupCard";
import GroupSwipper from "../components/Home/GroupSwipper";
import SearchBar from "../components/Home/SearchBar";
import PostComponent from "../components/PostComponent";
import PostDeniedModal from "../components/PostDeniedModal";
import ViewedByModal from "../components/ViewedByModal";
import hit from "../services/apiManager";

export default function Home() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const feeds = useSelector(state => state.homes?.feeds)
    const groups = useSelector(state => state?.team?.team)
    const user = useSelector(state => state?.auth?.user);
    const interactionList = useSelector(state => state?.interactions?.list)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showRejectModal, setShowRejectModal] = useState(false)
    const [rejectDetail, setRejectDetail] = useState(null)
    const [deleteId, setDeleteId] = useState("")
    const [showViewdBy, setShowViewdBy] = useState(false)
    const [viewedByDetail, setViewedByDetail] = useState([])
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [search, setSearch] = useState("")
    const [search1, setSearch1] = useState("")
    const [filterFeed, setFilterFeed] = useState([])
    const [feedLimit, setFeedLimit] = useState(20)
    const [teamsList, setTeamsList] = useState([])
    const [plusButtonModal, setPlusButtonModal] = useState(false)
    const [tabValue, setTabValue] = useState(0);
    const location = useLocation()
    const count = useSelector(state => state.homes?.count)
    const totalPage = useSelector(state => state.homes?.totalPage)

    useEffect(() => {
        setFilterFeed(feeds)
    }, [feeds])

    const setFeed = (value) => {
        dispatch(setHomeStateData({ key: "feeds", value: value }))
    }

    const setCount = (value) => {
        dispatch(setHomeStateData({ key: "count", value: value }))
    }

    const setTotalPage = (value) => {
        dispatch(setHomeStateData({ key: "totalPage", value: value }))
    }

    const setIsGettingMore = (value) => {
        dispatch(setHomeStateData({ key: "isGettingMore", value: value }))
    }

    useEffect(() => {
        const fetchData = async () => {
            if (search1.trim()) {
                const key = search1.trim().toLowerCase();
                if (key !== "") {
                    const filteredFeeds = feeds?.filter(x =>
                    (x?.category?.toLowerCase().includes(key) ||
                        x?.txt?.toLowerCase().includes(key) ||
                        x?.user?.firstName?.toLowerCase().includes(key) ||
                        x?.user?.lastName?.toLowerCase().includes(key))
                    );
                    setFilterFeed(filteredFeeds);
                    setFeed(filteredFeeds);
                } else {
                    setCount(1);
                    await getAllFeeds(1);
                }
            } else {
                await getAllFeeds(1);
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(position => {
                        dispatch(setCurrentLocation({ lat: position.coords.latitude, lng: position.coords.longitude }));
                    });
                }
            }
        };
        fetchData();
    }, [search1, tabValue]);

    useEffect(() => {
        const trimmedSearch = search?.trim().toLowerCase() || "";
        if (trimmedSearch === "") {
            let temp = groups.slice(0, 2);
            setTeamsList(temp);
        } else {
            let filteredTeams = groups?.filter(group =>
                String(group.name).toLowerCase().includes(trimmedSearch)
            ).slice(0, 2);
            setTeamsList(filteredTeams);
        }
    }, [groups, search]);

    useEffect(() => {
        getFirebaseToken().then(token => {
            updateUserProfile(token)
        })
    }, [])

    const updateUserProfile = async (t) => {
        try {
            await hit(updateUser, "patch", { "fcmToken": t })
        } catch (e) {
        } finally {
        }
    }

    useEffect(() => {
        dispatch(getAllNotifications())
        getuserInteractions()
        dispatch(getAllTeamThunk())
        dispatch(getAllTeamIds())
    }, [location])

    const likePost = async (id) => {
        try {
            let res = await hit(`${post?.post}/${id}/like`, "post")
            if (res?.err) {
                showToast(res?.msg)
            }
        } catch (e) {
        } finally {
        }
    }

    const deletePost = async (id) => {
        setShowDeleteModal(false)
        try {
            dispatch(setLoading(true))
            let res = await hit(`${post?.post}/${id}`, "delete")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                showToast("Post deleted successfully")
                getAllFeeds(count)
            }
        } catch (e) {
        } finally {
            setDeleteId("")
            dispatch(setLoading(false))
        }
    }

    const deleteF = (id) => {
        setDeleteId(id)
        setTimeout(() => {
            setShowDeleteModal(true)
        }, 700);
    }

    const votePoll = async (selected, id) => {
        try {
            dispatch(setLoading(true))
            let res = await hit(post?.poll, "post", { "postId": id, "selected": selected })
            if (res?.err) {
                showToast(res?.msg)
            } else {
                getAllFeeds(count)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const approve = async (id, approve, ind) => {
        if (approve == 2) {
            setShowRejectModal(true)
            setRejectDetail(ind)
            return
        }
        await hit(`${post.postApproval}/${id}`, "patch", { isApproved: approve })
    }

    const rejectPost = async (detail, reason) => {
        if (reason != '') {
            let temp = [...feeds]
            let index = temp.indexOf(detail)
            temp.splice(index, 1)
            setFeed(temp)
            await hit(`${post.postApproval}/${detail?._id}`, "patch", { isApproved: 2, reason: reason.trim() })
            setShowRejectModal(false)
        } else {
            showToast("Reject reason required!", 'error')
        }
    }

    function componentDidMount(id) {
        if (navigator.geolocation) {
            dispatch(setLoading(true))
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    let myloc = { latitude: position.coords.latitude, longitude: position.coords.longitude }
                    getAddress(position?.coords, id, myloc)
                },
                (error) => {
                    dispatch(setLoading(false))
                    console.log("error", error.code, error.message);
                }
            );
        }
    }

    function getAddress(cords, id, myloc) {
        fetch(
            'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
            (cords?.latitude ?? 0.0) +
            ',' +
            (cords?.longitude ?? 0.0) +
            '&key=' +
            'AIzaSyAPOiIA8anXQrrCQQXamQ_60HOkDHtri_Q',
        )
            .then(async res => {
                let resjson = await res.json();
                if (resjson.results.length > 0) {
                    let addressComponent = resjson.results[0].address_components;
                    let address = addressComponent[1]?.long_name + ' ' + addressComponent[2]?.long_name + ' ' + addressComponent[3]?.long_name + ' ' + addressComponent[5]?.long_name
                    interactPost(id, address, myloc)
                }
            })
            .catch(error => console.log('results error => ', error.message));
    }

    const interactPost = async (id, add, myloc) => {
        if (myloc == null) {
            dispatch(setLoading(false))
            return
        }
        try {
            let res = await hit(post?.interact, "post", {
                "postId": id,
                "last_checkin_location": {
                    "lat": myloc?.latitude,
                    "lng": myloc?.longitude,
                    "address": add
                },
            })
            if (res?.err) {
                showToast(res?.msg)
                dispatch(setLoading(false))
            } else {
                getuserInteractions()
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))

        }
    }

    const getuserInteractions = async () => {
        try {
            let res = await hit(`${post?.interact}?user=${user?._id}`, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                dispatch(setInteractionList(res?.data))
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const viewPost = async (id) => {
        await hit(`${post?.viewPost}/${id}`, "patch")
    }

    const getAllFeeds = async (pg) => {
        try {
            dispatch(setLoading(true))
            let url = ""
            if (tabValue == 1) {
                url = `${post?.post}?page=${pg}&limit=${feedLimit}&populate=likes,user,viewsBy,pollSelected.user,groups.members&global=true&date=${moment().unix() * 1000}&isScorePost=true`
            } else {
                url = `${post?.post}?page=${pg}&limit=${feedLimit}&populate=likes,user,viewsBy,pollSelected.user,groups.members&global=true&date=${moment().unix() * 1000}&isScorePost=false`
            }
            let res = await hit(url, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                setFeed(res?.data?.results)
                setTotalPage(res?.data?.totalPages)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    const refreshFeeds = async (pg) => {
        try {
            dispatch(setLoading(true))
            setIsGettingMore(true)
            let url = ""
            if (tabValue == 1) {
                url = `${post?.post}?page=${pg}&limit=${feedLimit}&populate=likes,user,viewsBy,pollSelected.user,groups.members&global=true&date=${moment().unix() * 1000}&isScorePost=true`
            } else {
                url = `${post?.post}?page=${pg}&limit=${feedLimit}&populate=likes,user,viewsBy,pollSelected.user,groups.members&global=true&date=${moment().unix() * 1000}&isScorePost=false`
            }
            let res = await hit(url, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                setTotalPage(res?.data?.totalPages)
                let temp = []
                let x = feeds
                let result = []
                result = res?.data?.results
                temp = x.concat(result)
                setFeed(temp)
            }
        } catch (e) { }
        finally {
            setIsGettingMore(false)
            setCount(pg)
            dispatch(setLoading(false))
        }
    }

    const style = { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, pt: 4, px: 4, pb: 5 };
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Modal
                open={plusButtonModal}
                onClose={() => setPlusButtonModal(false)}>
                <Box sx={{ ...style, width: "450px" }}>
                    <h5 id="child-modal-title" style={{ textAlign: "center", margin: "0 0 20px" }}>Select Action</h5>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button
                            onClick={() => {
                                dispatch(setToInitalPostData())
                                navigate("/user/post1")
                            }}
                            sx={{ flex: 1, borderRadius: "5px", height: "45px" }}
                            variant="contained">
                            Post
                        </Button>
                        <Button
                            onClick={() => { navigate("/user/broadcast") }}
                            startIcon={
                                <Box
                                    sx={{ height: "15px", width: "15px" }}
                                    component={"img"} src="assets/home/chat.png" >
                                </Box>
                            }
                            sx={{ flex: 1, marginLeft: "5%", borderRadius: "5px", height: "45px" }}
                            variant="contained">
                            Broadcast
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Box sx={{ px: "5%", pt: "20px", overflowY: "auto", display: "flex", pr: "8%", flex: 1, width: "100%", background: "linear-gradient(119deg,#E6F6F9,white,rgb(253,243,213))" }}>
                <Box sx={{ flex: 1, display: "flex", flexDirection: "column", minHeight: "100px", }}>
                    <Box id="topHome" marginTop={"-15px"} />
                    <Box marginTop={"20px"} />
                    <GroupSwipper />
                    <box
                        style={{margin: "20px 0"}}
                        sx={{
                            display: "flex",
                            width: "100%", // Optional: Set the width of the container
                        }}>
                        <SearchBar
                            value={search1}
                            onChange={(r) => {
                                setSearch1(r);
                            }}
                            placeholder={"Search...."}
                            style={{
                                width: "80% !important",
                                float: 'left'
                            }}
                        />
                        <Button
                            onClick={() => {
                                setPlusButtonModal(true)
                            }}
                            sx={{
                                borderRadius: "5px",
                                height: "45px",
                                width: "45px", // Ensures button is a square if width isn't specified
                                padding: 0,
                                float: "right"
                            }}
                            variant="contained">
                            <img src="assets/home/plus.png" style={{ height: 25 }} alt="Add" />
                        </Button>
                    </box>
                    {/* tabs start Here */}
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabValue} onChange={handleTabChange}>
                                <Tab style={{ width: "50%", background: tabValue == 0 ? "#0DB1CF" : "#fff", color: tabValue == 0 ? "#fff" : "#0DB1CF" }} label="News Feed" />
                                <Tab style={{ width: "50%", background: tabValue == 1 ? "#0DB1CF" : "#fff", color: tabValue == 1 ? "#fff" : "#0DB1CF" }} label="Scoring Posts" />
                            </Tabs>
                        </Box>
                    </Box>
                    {/* tabs end Here */}
                    {tabValue == 0 && <Typography sx={{ fontWeight: "600", textAlign: "left", marginTop: "20px" }}>News Feed</Typography>}
                    {tabValue == 1 && <Typography sx={{ fontWeight: "600", textAlign: "left", marginTop: "20px" }}>Scoring Posts</Typography>}
                    {filterFeed.map((i, j) => {
                        let isCheckedin = "in"
                        let temp = interactionList?.filter(x => x?.postId == i?._id)
                        if (temp?.length > 0) {
                            if (temp[0]?.current_status == "in") {
                                isCheckedin = "out"
                            } else {
                                isCheckedin = "in"
                            }
                        }
                        return (
                            <PostComponent
                                i={i} j={j}
                                key={i?._id}
                                onPressProfile={() => {
                                    viewPost(i?._id)
                                    if (user?._id == i?.user?._id) {
                                        return
                                    }
                                }}
                                postLiked={liked => { likePost(liked); viewPost(i?._id) }}
                                onPressDelete={id => {
                                    viewPost(i?._id)
                                    deleteF(id)
                                }}
                                onPressComment={(groupId) => {
                                    viewPost(i?._id)
                                    navigate("/user/comments", { state: { id: i?._id, from: 'home', groupId } })
                                }}
                                onPressPost={(data) => {
                                    viewPost(i?._id)
                                    if (data?.type == "photo") {
                                        window.open(image_url + data?.img, "_blank")
                                    } else {
                                        let ind = i?.thumbnails?.indexOf(data?.img)
                                        let vid = i?.videos[ind]
                                        window.open(image_url + vid, "_blank")
                                    }
                                }}
                                selectPoll={(selected) => {
                                    viewPost(i?._id)
                                    votePoll(selected, i?._id)
                                }}
                                onPressApprove={() => {
                                    viewPost(i?._id)
                                    approve(i?._id, 1)
                                }}
                                onPressReject={() => {
                                    approve(i?._id, 2, i)
                                }}
                                onPressCheck={() => {
                                    viewPost(i?._id)
                                    componentDidMount(i?._id)
                                }}
                                onPressInteraction={() => {
                                    viewPost(i?._id)
                                    if (i?.verifyBy?.includes(user?._id)) {
                                        navigate("/user/interaction", { state: { postid: i?._id } })
                                    }
                                }}
                                isCheckedin={isCheckedin}
                                onPressViewed={(members, grpId, viewsBy) => {
                                    viewPost(i?._id)
                                    setSelectedGroup(grpId)
                                    setTimeout(() => {
                                        setShowViewdBy(true)
                                        setViewedByDetail({ viewed: viewsBy, memb: members })
                                    }, 400);
                                }}
                            />
                        )
                    })}
                    {totalPage > count &&
                        <Button
                            onClick={() => { refreshFeeds(count + 1) }}
                            sx={{ marginTop: "20px", color: "#58C0DC" }} variant="outline">
                            Load More
                        </Button>}
                    <Box sx={{ height: "20px" }} />
                </Box>
                <Box sx={{ flex: 1, marginLeft: "5%", minHeight: "100px", marginTop: '0.3rem' }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography
                            sx={{ fontWeight: "600" }}>
                            Groups
                        </Typography>
                        <Typography
                            onClick={() => {
                                navigate("/user/teams")
                            }}
                            sx={{ fontSize: pxToRem(13), cursor: "pointer" }}>
                            View All
                        </Typography>
                    </Box>
                    <div
                        className="">
                        <SearchBar
                            value={search}
                            onChange={v => setSearch(v)}
                            placeholder={"Search...."}
                        />
                    </div><br />
                    <Box
                        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        {teamsList?.map((x, i) =>
                            <GroupCard
                                onPressItem={() => {
                                    dispatch(setSelectedTeam(x))
                                    navigate("/user/teams")
                                }}
                                item={x}
                                style={{ marginLeft: i != 0 ? "4%" : "0%" }}
                            />
                        )}
                    </Box>
                </Box>
                <DeleteModal
                    open={showDeleteModal}
                    handleClose={() => {
                        setShowDeleteModal(false)
                        setDeleteId("")
                    }}
                    onPressNo={() => {
                        setShowDeleteModal(false)
                        setDeleteId("")
                    }}
                    onPressYes={() => {
                        deletePost(deleteId)
                    }}
                />
                <ViewedByModal
                    open={showViewdBy}
                    arr={viewedByDetail}
                    setOpen={setShowViewdBy}
                    selectedGroup={selectedGroup}
                    onPressCancel={() => {
                        setShowViewdBy(false)
                    }}
                />
                <PostDeniedModal
                    open={showRejectModal}
                    setOpen={setShowRejectModal}
                    outPressed={() => setShowRejectModal(false)}
                    onPressOk={(t) => {
                        rejectPost(rejectDetail, t)
                    }}
                />
            </Box>
        </>
    )
}