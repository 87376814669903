
// production
// const base_url = "https://api.ourculturescore.com/"
// const image_url = "https://d1jfvl8f1yx3kr.cloudfront.net/"

// dev
// const base_url = "https://apidev.ourculturescore.com/v1/"
// const image_url = "https://d21yobxcxp20i3.cloudfront.net/"

// stage api base url
// const base_url = "https://apistage.ourculturescore.com/v1/"
// const image_url = "https://d21yobxcxp20i3.cloudfront.net/"


var port = ''
var base_url = ''
var image_url = ''
var mode = "Production"
if (mode == "Dev") {
    port = 4001
    base_url = "https://apidev.ourculturescore.com/v1/"
    // image_url = "https://d21yobxcxp20i3.cloudfront.net/"
    // base_url = "http://localhost:3001/v1/"
    image_url = "https://d1jfvl8f1yx3kr.cloudfront.net/" //prod cf because data dumb of prod in dev
} else if (mode == "Stage") {
    port = 4002
    base_url = "https://apistage.ourculturescore.com/v1/"
    image_url = "https://d21yobxcxp20i3.cloudfront.net/"
} else if (mode == "Production") {
    port = 5079
    base_url = "https://api.ourculturescore.com/"
    // base_url = "http://localhost:5078/"
    image_url = "https://d1jfvl8f1yx3kr.cloudfront.net/"
}

const default_pic = "public/user.png"
const auth = {
    login: "auth/login",
    register_otp_send: "auth/register_otp_send",
    register: "auth/register",
    // verifyotp: "auth/check-otp-auth",
    resendotp: "auth/resend-otp-auth",
    forgotpass: "auth/forgot-password",
    verifyforgot: "auth/check-otp",
    resetpass: "auth/reset-password",
    socialLogin: "auth/social-login",
}
const userDetail = "/users"
const updateUser = "/users/self"
const uploads = "/uploads/new"
const uploadsVid = "/uploads/video/new"
const teams = {
    joinTeam: "/teams/join",
    createTeam: "/teams",
    addAdmin: "/teams/addAdmin",
    removeAdmin: "/teams/removeAdmin",
    getAdmins: "/teams/admins",
    members: "/teams/members",
    changeScoring: "/teams/ableToScore"
}
const sendEmail = "/mail/sendCode"
const subgroups = {
    create: "/teams/sub",
    addMember: "/teams/sub/addMember",
    removeMember: "/teams/sub/removeMember",
}
const post = {
    post: "/posts",
    poll: "/posts/poll",
    postApproval: "/posts/approve",
    interact: "/posts/interactions",
    verify: "/posts/interactions/verify",
    deny: "/posts/interactions/deny",
    addTime: "/posts/interactions/add",
    check: "/posts/interactions/check",
    viewPost: "/posts/view",
    comments: "/posts/comments"
}
const events = {
    event: "/events",
    attend: "/events/attend",
}
const categories = "/categorys"
const friendsApi = "/friends"
const notifications = "/notifications"
const notificationsDelete = "notifications/del"
const notificationsDeleteAll = "notifications/clear_all"
const verifynoti = "/notifications/verify"
const verifyComments = "/notifications/verifyComments"
const childs = "/childs"
const gettc = "/adminMisc"
const faq = {
    getFaqs: "/faqs"
}
const scores = {
    getscore: "/scores",
    user: "/scores/user",
    ranking: "/scores/ranking",
    history: "/scores/history"
}
const manuals = "/manuals"
const gallery = "/gallerys"
const drafts = "/drafts"
const supports = "/supports"
module.exports = { port, base_url, auth, default_pic, uploads, verifyComments, image_url, childs, userDetail, updateUser, teams, sendEmail, subgroups, post, uploadsVid, events, categories, friendsApi, notifications, notificationsDelete, gettc, faq, verifynoti, scores, manuals, gallery, drafts, supports, notificationsDeleteAll }