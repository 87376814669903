import { Avatar, Box, Button, Popover, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { image_url, post } from "../services/endpoints";
import { pxToRem } from "../theme/typography";
import { Carousel } from 'react-responsive-carousel';
import { setLoading } from "../store/load";
import showToast from "../utils/showToast";
import moment from "moment";
import ProgressBar from "@ramonak/react-progress-bar";
import * as geolib from 'geolib';
import hit from "../services/apiManager";

export default function PostComponent({ i, j, isDraft = false, postLiked, isInsideGroup, grpId, isCheckedin, onPressEdit, onPressComment, onPressDelete, onPressPost, styleView, selectPoll, onPressApprove, onPressReject, groupDetail, onPressInteraction, onPressCheck, onPressViewed, containerStyle }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(state => state?.auth?.user?._id)
    const groups = useSelector(state => state?.team?.team)
    const teamIds = useSelector(state => state?.teamIds)
    const currentLocation = useSelector(state => state.currentlocation)
    const [isLiked, setIsLiked] = useState(false)
    const [isLiking, setIsLiking] = useState(false);
    const [likeLine, setLikeLine] = useState("No likes yet")
    const [selectedPoll, setSelectedPoll] = useState([])
    const [voters, setVoters] = useState([])
    const [totalVotes, setTotalVotes] = useState(0)
    const [voting, setVoting] = useState([])
    const [votedFor, setVotedFor] = useState("")
    const [isTextOnly, setIsTextOnly] = useState(false)
    const [pollEnded, setPollEnded] = useState(false)
    const [approvedNow, setApprovedNow] = useState(0)
    const [allPost, setAllPost] = useState([])
    const [groupName, setGroupName] = useState()
    const [isEnable, setIsEnable] = useState(false)
    const theme = useTheme()
    const [popAnchor, setPopAnchor] = useState(null)
    const [quizPoints, setQuizPoints] = useState({
        total: 1,
        earned: 0
    })
    let canApprove = false
    let commentImg = false
    let commentDoc = false
    let isGeo = false

    useEffect(() => {
        if (i?.eventLocation?.lat && currentLocation?.lat) {
            let distance = geolib.getDistance({ latitude: i.eventLocation.lat, longitude: i.eventLocation.lng }, { latitude: currentLocation.lat, longitude: currentLocation.lng })
            if (distance < 800) {
                setIsEnable(true)
            } else {
                setIsEnable(false)
            }
        }
    }, [i, currentLocation])

    useEffect(() => {
        if (i?.likes?.length > 0) {
            let temp = i?.likes
            let fil = temp?.filter(x => x?._id == user)
            if (fil?.length > 0) {
                setIsLiked(true)
            } else {
                setIsLiked(false)
            }
        }
        if (i?.likes?.length == 0) {
            setLikeLine("No likes yet")
        }
        if (i?.likes?.length == 1) {
            setLikeLine(`Liked by ${i?.likes[0]?.firstName}`)
        }
        if (i?.likes?.length > 1) {
            setLikeLine(`Liked by ${i?.likes[0]?.firstName} and ${(i?.likes?.length) - 1 ?? 0} other`)
        }
        if (i?.pollSelected) {
            let temp = []
            let tot = i?.pollSelected?.length
            setSelectedPoll(i?.pollSelected)
            setTotalVotes(tot)
            if (i?.pollSelected > 4) {
                setVoters(i?.pollSelected.splice(0, 4))
            } else {
                setVoters(i?.pollSelected)
            }
            temp = i?.pollSelected
            let arr = temp?.filter(x => x?.user?._id == user)
            if (arr.length > 0) {
                setVotedFor(arr[0]?.option)
            }
        }
        if (i?.photos.length == 0) {
            if (i?.thumbnails.length == 0) {
                if (i?.postType1 != "theme") {
                    setIsTextOnly(true)
                }
            }
        }
        if (i?.pollOptions) {
            let temp = []
            i?.pollOptions.forEach(e => {
                let name = e
                let val = i?.pollSelected?.filter((x => x?.option == e)).length
                temp.push({ name, val })
            });
            setVoting(temp)
        }
        if (i?.pollEndTime) {
            let hasended = false
            let endtime = moment(i?.pollEndTime).unix()
            let curr = moment(new Date()).unix()
            if (endtime - curr < 0) {
                hasended = true
                setPollEnded(hasended)
            } else {
                hasended = false
                setPollEnded(hasended)
            }
        }
        if (i?.photos || i?.thumbnails) {
            let temp = []
            i?.photos?.forEach(e => {
                let x = { img: e, type: "photo" }
                temp.push(x)
            });
            i?.thumbnails?.forEach((e, ind) => {
                let x = { img: e, type: "video", video: i?.videos?.[ind] };
                temp.push(x);
            });
            setAllPost(temp)
        }
        if (i.postType1 == "quiz") {
            if (i?.quizedBy?.map(x => x.user)?.includes(user)) {
                let earned = i?.quizedBy?.filter(x => x.user == user)
                earned = earned.map(x => x.point).reduce((a, b) => a + b, 0)
                let total = i?.quizedBy?.find(x => x.user == user)?.total
                setQuizPoints({
                    earned,
                    total
                })
            }
        }
    }, [i])

    useEffect(() => {
        if (i?.groups) {
            if (isInsideGroup) {
                let temp = []
                i?.groups?.forEach(e => {
                    if (e?._id == grpId) {
                        temp.length > 0 ?
                            temp.push({ name: e?.name, isHidden: true, members: e?.members, _id: e?._id }) :
                            temp.push({ name: e?.name, isHidden: false, members: e?.members, _id: e?._id })
                    }
                })
                setGroupName(temp)
                return
            } else {
                let temp = []
                i?.groups?.filter(x => {
                    if (teamIds?.adminGroups?.includes(x?._id) || teamIds?.memberGroups?.includes(x?._id)) {
                        return true
                    }
                    return false
                })?.forEach(e => {
                    if (e?.active == 1) {
                        temp.length > 0 ?
                            temp.push({ name: e?.name, isHidden: true, members: e?.members, _id: e?._id }) :
                            temp.push({ name: e?.name, isHidden: false, members: e?.members, _id: e?._id })
                    }
                })
                setGroupName(temp)
            }
        }
    }, [i, groups, teamIds])

    if (i?.isScorePost == true) {
        if (isDraft == false) {
            if (moment(i?.scoreStartTime).toDate() > moment().toDate()) {
                return (<></>)
            }
        }
        if (i?.scoreBy?.includes("geo")) {
            isGeo = true
        }
    }

    if (i?.lastTypeComment == 1) {
        commentImg = true
    }

    if (i?.lastTypeComment == 2) {
        commentDoc = true
    }

    if (!i?.isScorePost && i?.isApproved == 0) {
        let g = []
        i?.groups?.map(x => {
            if (x?.admins) {
                for (let a of x?.admins) {
                    g.push(a)
                }
            }
        })
        if (g?.includes(user)) {
            canApprove = true
        }
    }

    if ((canApprove == false && !i?.isScorePost && i?.isApproved == 0) || groupName?.length == 0) {
        return (
            <></>
        )
    }

    const REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;

    const handleLikeClick = async () => {
        if (isLiking) return;  // Prevent multiple clicks
        setIsLiking(true);
        setIsLiked(!isLiked);
        await postLiked(i?._id);  // Wait for the like API call to complete
        setIsLiking(false);
    };

    const Paragraph = ({ paragraph }) => {
        if (!paragraph || paragraph.trim().length === 0) {
            return null;
        }
        const paragraphArray = paragraph.match(/\S+|\n/g);
        return (
            <div style={{ flex: 1, maxWidth: "400px", wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
                {paragraphArray.map((word, index) => {
                    if (word.match(REGEX)) {
                        return (
                            <a key={index} href={word} className="text-blue-400">
                                {word}
                            </a>
                        );
                    } else {
                        return (
                            <span key={index}>
                                {word}{(word === '\n' || word === ' ') ? '' : " "}
                            </span>
                        );
                    }
                })}
            </div>
        );
    };

    const getPostDetails = async (id, grpId) => {
        try {
            dispatch(setLoading(true))
            const res = await hit(`${post?.post}/${id}`, "get")
            if (res?.err) {
                showToast(res?.msg)
            } else {
                let postDetails = res?.data
                let selectedGroup = postDetails?.groups?.filter((pg) => pg?._id == grpId)
                onPressViewed(selectedGroup[0]?.members ?? [], grpId, postDetails?.viewsBy)
            }
        } catch (e) {
        } finally {
            dispatch(setLoading(false))
        }
    }

    return (
        <Box key={i?._id} sx={[{ flex: 1, marginTop: "20px", borderRadius: "5px", textAlign: "left", border: "2px solid", borderColor: i?.isScorePost == true ? "#15ff00" : "transparent", p: "10px", background: "white", boxShadow: "0px 5px 20px #26262626" }, containerStyle]}>
            {groupName?.map((grpData, index) => {
                let grpView = 0
                i?.viewsBy.forEach(e => {
                    let found = false
                    if (grpData?.members?.find(x => x == e)) { // changes
                        found = true
                    }
                    if (found == true) {
                        grpView = grpView + 1
                    }
                });
                if (grpData?.isHidden) {
                    return (
                        <Box sx={[{ width: "100%", cursor: "pointer", backgroundColor: "white", boxShadow: "0px 5px 20px #26262626", padding: "10px", marginBottom: "8px", borderRadius: "8px", flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', display: "flex" }]}
                            onClick={() => {
                                let a = [...groupName]
                                a[index].isHidden = false
                                setGroupName([...a])
                            }}>
                            <Typography style={{ fontSize: "12px", marginTop: "4px" }}>Multi Post: <Typography component={"span"} style={{ fontWeight: "500", fontSize: "12px" }}>{grpData?.name ?? ""}</Typography></Typography>
                            <Typography color={"primary"} style={{ fontSize: "12px", cursor: "pointer", marginTop: "4px", fontWeight: "500" }}>+ Show Full Post</Typography>
                        </Box>
                    )
                }
                return (
                    <>
                        <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between", cursor: "pointer", marginBottom: "10px", alignItems: "center" }}>
                            {i?.groups?.length == 1 ?
                                <Typography style={{ fontSize: pxToRem(12), fontWeight: "500", marginTop: "2px" }}>Group post: <Typography component={"span"} style={{ fontSize: pxToRem(12), fontWeight: "600", marginTop: "2px" }}>{grpData?.name}</Typography></Typography>
                                :
                                <Typography style={{ fontSize: pxToRem(12), fontWeight: "500", marginTop: "2px" }}>Multi Post: <Typography component={"span"} style={{ fontSize: pxToRem(12), fontWeight: "600", marginTop: "2px" }}>{grpData?.name ?? ""}</Typography></Typography>
                            }
                            {!isDraft && <Typography sx={{ cursor: "pointer" }} onClick={() => { getPostDetails(i?._id, grpData?._id) }}>
                                <Typography color={"primary"} sx={{ fontWeight: "500", fontSize: "12px", marginTop: "4px" }}>Viewed By <Typography component={"span"} color={"primary"} style={{ fontSize: "12px", fontWeight: "600" }}>{grpView}/{grpData?.members?.length}</Typography></Typography>
                            </Typography>}
                        </Box>
                        {/* post box */}
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box
                                onClick={() => {
                                    navigate("/user/details", { state: { userData: i?.user, groupDetail } })
                                }}
                                sx={{ display: "flex", flex: 1, cursor: "pointer", alignItems: "center" }}>
                                <Avatar
                                    src={image_url + i?.user?.profilePic}
                                    style={{ height: "40px", width: "40px" }}
                                />
                                <Box sx={{ marginLeft: "15px", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                    <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14) }}>{i?.user?.firstName} {i?.user?.lastName}</Typography>
                                    <Typography sx={{ fontSize: pxToRem(10) }}>{moment(i?.createdAt).format("MMM Do, YYYY")}</Typography>
                                </Box>
                            </Box>
                            {user == i?.user?._id &&
                                <Box
                                    onClick={(e) => { setPopAnchor(e.currentTarget) }}
                                    component={"img"}
                                    src={isDraft ? "assets/draft/edit.svg" : "assets/post/dots.png"}
                                    sx={{ height: "16px", cursor: "pointer", width: "16px", marginLeft: "5px", objectFit: "contain" }}
                                />}
                        </Box>
                        {i?.category != "" && <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14), marginTop: "10px" }}>{i?.category}</Typography>}
                        {(i?.postType1 != "theme" && i?.postType1 !== "quiz") && <Typography sx={{ fontSize: pxToRem(14) }}>
                            <Paragraph paragraph={i?.txt} />
                        </Typography>}
                        {i?.postType1 == "quiz" &&
                            <>
                                <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14), marginTop: "10px" }}>{i?.quizTitle}</Typography>
                                <Typography sx={{ fontSize: pxToRem(14) }}><Paragraph paragraph={i?.quizDesc} /></Typography>
                                {!isDraft &&
                                    <>
                                        <Typography color="primary" style={{ fontWeight: "500", fontSize: pxToRem(12), marginTop: "5px" }}><Typography sx={{ fontWeight: "600", fontSize: pxToRem(12), color: "black" }} component={"span"}>Timing: </Typography>{moment(i.scoreStartTime).format("DD/MM/YYYY hh:mm a")} - {moment(i.scoreEndTime).format("DD/MM/YYYY hh:mm a")}</Typography>
                                        {i?.quizedBy?.filter(x => x.user == user)?.length > 0 && <Typography sx={{ fontWeight: "600", fontSize: pxToRem(18), marginTop: "5px" }}>Total Earned Points : {quizPoints?.earned}/{quizPoints?.total}</Typography>}
                                        {
                                            (i?.quizedBy?.filter(x => x.user == user)?.length < i?.quizScoreCount && quizPoints?.earned < quizPoints?.total && moment(i?.scoreEndTime).toDate() > moment().toDate())
                                            && (i?.user?._id != user) &&
                                            <>
                                                <Button
                                                    onClick={() => {
                                                        navigate("/user/quiz", { state: { gId: grpData._id, postId: i?._id } })
                                                    }}
                                                    sx={{ width: "100%", marginY: "10px", marginTop: "5px", borderRadius: "5px", ":hover": { background: "#0DB1D41A" }, color: "black", height: "50px", background: "#0DB1D41A" }}
                                                    variant="contained"
                                                >Take a quiz
                                                </Button>
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                        {isDraft &&
                            <>
                                <Typography sx={{ fontWeight: "500", fontSize: pxToRem(10), marginTop: "6px" }}>Post on: <Typography component={"span"} sx={{ fontSize: pxToRem(10), fontWeight: "600" }}>{moment((i?.scoreStartTime)).format("MMM, DD, YYYY")}</Typography></Typography>
                                <Typography sx={{ fontWeight: "500", fontSize: pxToRem(10), marginTop: "2px" }}>Closed on: <Typography component={"span"} sx={{ fontSize: pxToRem(10), fontWeight: "600" }}>{moment((i?.scoreEndTime)).format("MMM, DD, YYYY")}</Typography></Typography>
                                <Typography sx={{ fontWeight: "500", fontSize: pxToRem(10), marginTop: "2px" }}>Points to be earned:
                                    {i?.postType1 == "quiz" && <Typography sx={{ fontWeight: "700", fontSize: pxToRem(12) }}>{"\n"}Quiz: {i?.quizData?.map(x => x.point).reduce((a, b) => a + b, 0)} Points</Typography>}
                                    {i?.scoreBy?.includes("likes") && <Typography sx={{ fontWeight: "700", fontSize: pxToRem(12) }}>{"\n"}Liking: {i?.scorePoint} Points</Typography>}
                                    {i?.scoreBy?.includes("verification") && <Typography sx={{ fontWeight: "700", fontSize: pxToRem(12) }}>{"\n"}Admin Verification: {i?.scorePoint1 * i?.scoreCount} Points</Typography>}
                                    {i?.scoreBy?.includes("geo") && <Typography sx={{ fontWeight: "700", fontSize: pxToRem(12) }}>{"\n"}Geo-fencing: {i?.scorePoint2} Points</Typography>}
                                </Typography>
                            </>
                        }
                        {i?.scoreBy?.includes("geo") &&
                            <>
                                <Typography onClick={() => {
                                    window.open(`https://www.google.com/maps/search/?api=1&query=${i?.eventLocation?.lat},${i?.eventLocation?.lng}`, "_blank")
                                }} color="primary" style={{ fontWeight: "500", cursor: "pointer", fontSize: pxToRem(12), marginVertical: "8px" }}><Typography sx={{ fontWeight: "600", fontSize: pxToRem(12) }} component={"span"}>Location: </Typography>https://www.google.com/maps/search/?api=1&query={i?.eventLocation?.lat},{i?.eventLocation?.lng}</Typography>
                                <Typography color="primary" style={{ fontWeight: "500", fontSize: pxToRem(12) }}><Typography sx={{ fontWeight: "600", fontSize: pxToRem(12) }} component={"span"}>Timing: </Typography>{moment(i.scoreStartTime).format("DD/MM/YYYY hh:mm a")} - {moment(i.scoreEndTime).format("DD/MM/YYYY hh:mm a")}</Typography>
                            </>
                        }
                        {i?.postType1 == "poll" ?
                            <Box sx={{ borderBottomWidth: "1px", paddingBottom: "10px", marginVertical: "16px" }}>
                                {!pollEnded ?
                                    <Box>
                                        {i?.pollOptions?.map((i, j) => {
                                            let selectedOption = "DefDefFault"
                                            let ar = selectedPoll.filter(x => x?.user?._id == user)
                                            selectedOption = ar[0]?.option
                                            return (
                                                <Box
                                                    key={j.toString()}
                                                    sx={{ marginBottom: "4px", cursor: "pointer", padding: "4px", display: "flex", alignItems: 'center' }}
                                                    onClick={() => {
                                                        selectPoll(i)
                                                    }}
                                                >
                                                    <Box sx={{ height: "20px", width: "20px", borderRadius: "20px", borderWidth: "1px", borderColor: "grey", display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                                        {selectedOption == i && <Box component={"img"} src={"/assets/post/check.png"} sx={{ height: "20px", width: "20px", objectFit: 'contain' }} />}
                                                    </Box>
                                                    <Typography style={{ marginLeft: "10px", fontWeight: "600", fontSize: "14px" }}>{i}</Typography>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                    :
                                    <Box>
                                        {voting?.map((i, j) => {
                                            let vote = i?.val ? i?.val : 0
                                            let progress = ((vote / (totalVotes ? totalVotes : 0)) * 100) / 100
                                            if (Number.isNaN(progress)) {
                                                progress = 0
                                            }
                                            let percent = (progress * 100).toFixed(1)
                                            return (
                                                <Box key={j.toString()} sx={{ marginBottom: "10px" }}>
                                                    <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14) }}>{i?.name}</Typography>
                                                    <Box sx={{ display: "flex", alignItems: 'center' }}>
                                                        <Typography sx={{ fontWeight: "600", fontSize: "14px", marginRight: "16px", width: "14%" }}>{percent}%</Typography>
                                                        {/* aded progress */}
                                                        <ProgressBar bgColor="#10B0D4" completed={progress} width={"200px"} />
                                                        {/* <Progress.Bar progress={progress} width={200} height={8} color={appcolors.primary} /> */}
                                                        {votedFor == i?.name && <Box component={"img"} src={"/assets/post/check.png"} style={{ height: "20px", width: "20px", marginLeft: "8px", objectFit: 'contain' }} />}
                                                    </Box>
                                                </Box>
                                            )
                                        })}
                                    </Box>
                                }
                                <Box sx={{ display: 'flex', marginTop: "16px", alignItems: "center" }}>
                                    {voters?.length > 0 && <Box sx={{ display: 'flex', alignItems: 'center', marginRight: "16px" }}>
                                        {voters?.map((i, j) => {
                                            return (
                                                j < 4 ?
                                                    <Box
                                                        component={"img"}
                                                        key={j.toString()}
                                                        src={image_url + i?.user?.profilePic} sx={{ height: "26px", background: "red", width: "26px", borderRadius: "26px", marginLeft: j != 0 ? "-8px" : "0px" }}
                                                    />
                                                    :
                                                    null
                                            )
                                        })}
                                    </Box>}
                                    <Typography style={{ fontWeight: "600", fontSize: pxToRem(12) }}>Total Vote: {i?.pollSelected.length}</Typography>
                                </Box>
                            </Box>
                            :
                            i?.postType1 == "theme" ?
                                <Box sx={{ justifyContent: 'center', display: "flex", alignItems: 'center', borderRadius: "6px", padding: "5px", width: "100%", height: "auto", marginTop: "10px", backgroundColor: i?.postType1 == "theme" ? i?.themeColor : "grey", borderWidth: i?.postType1 == "theme" ? 1 : null, borderColor: "grey" }}>
                                    <Typography sx={{ fontWeight: "600", color: "white", fontSize: pxToRem(18), marginTop: "8px" }}>{i?.txt}</Typography>
                                </Box>
                                :
                                (!isTextOnly && allPost.length > 0 && i?.postType1 == "normal") &&
                                <>
                                    <MediaSwippers role={i?.user?.role} onPressPost={onPressPost} allPost={allPost} />
                                </>
                        }
                        <Box>
                            {i?.attachments?.map(x => {
                                return (
                                    <Box
                                        onClick={e => { window.open(image_url + x.key, "_blank") }}
                                        sx={{ display: "flex", marginTop: "10px", marginBottom: "5px", cursor: "pointer", position: "relative", alignItems: "center", alignSelf: "flex-start" }}>
                                        <Box component={"img"} sx={{ objectFit: "contain", height: "20px", width: "20px" }} src="assets/library/doc.svg" />
                                        <Typography color={"primary"} sx={{ fontWeight: "600", fontSize: pxToRem(14), marginLeft: "5px", textAlign: "justify" }}>{x?.name ?? x?.fileName}</Typography>
                                    </Box>
                                )
                            })}
                        </Box>
                        <Box sx={{ height: "10px" }} />
                        {
                            (isDraft || i.postType1 == "quiz") ?
                                <>
                                    {index != 0 && <Box sx={[{ padding: "10px", borderRadius: "8px", cursor: "pointer" }]}
                                        onClick={() => {
                                            let a = [...groupName]
                                            a[index].isHidden = true
                                            setGroupName([...a])
                                        }}>
                                        <Typography color={"primary"} sx={{ fontWeight: "600", fontSize: pxToRem(12), marginTop: "4px", textAlign: "right" }}>- Hide Full Post</Typography>
                                    </Box>}
                                </>
                                :
                                isGeo ?
                                    <>
                                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Box
                                                onClick={onPressInteraction}
                                                sx={{ cursor: "pointer" }}>
                                                <Typography sx={{ fontWeight: "500", fontSize: pxToRem(12) }}>Total interaction : {i?.interactions?.length}</Typography>
                                            </Box>
                                            {
                                                i?.user?._id == user && !(groups?.filter(x => x?._id == grpData?._id)[0]?.ableToScores?.includes(i?.user?._id)) ?
                                                    <></>
                                                    :
                                                    moment(i?.scoreEndTime).toDate() > moment().toDate() &&
                                                    <Button variant="contained" sx={{ backgroundColor: isCheckedin == "out" ? theme.palette.error.main : (isEnable ? "#28C26B" : "black"), height: "34px", width: "30%", cursor: "pointer", fontSize: "12px" }}
                                                        onClick={() => {
                                                            if (isCheckedin == "out") {
                                                                onPressCheck()
                                                                return
                                                            }
                                                            if (isEnable) {
                                                                onPressCheck()
                                                            } else {
                                                                alert("unable to check in due to not being near the event location")
                                                            }
                                                        }}>
                                                        {"Check" + " " + isCheckedin}
                                                    </Button>
                                            }
                                        </Box>
                                        {index != 0 &&
                                            <Box sx={[{ padding: "10px", borderRadius: "8px", cursor: "pointer" }]}
                                                onClick={() => {
                                                    let a = [...groupName]
                                                    a[index].isHidden = true
                                                    setGroupName([...a])
                                                }}>
                                                <Typography color={"primary"} sx={{ fontWeight: "600", fontSize: pxToRem(12), marginTop: "4px", textAlign: "right" }}>- Hide Full Post</Typography>
                                            </Box>
                                        }
                                    </>
                                    :
                                    approvedNow == 0 ?
                                        (canApprove == false ?
                                            <>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                                        <Box
                                                            onClick={handleLikeClick}
                                                            sx={{
                                                                height: "30px",
                                                                display: "flex",
                                                                cursor: isLiking ? "not-allowed" : "pointer",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                background: "white",
                                                                boxShadow: "0px 3px 5px #26262633",
                                                                width: "30px",
                                                                borderRadius: "40px",
                                                                opacity: isLiking ? 0.5 : 1,  // Optional: Visually indicate disabled state
                                                            }}>
                                                            <Box
                                                                component={"img"}
                                                                sx={{ height: "15px", width: "15px", objectFit: "contain" }}
                                                                src={isLiked ? "assets/post/heart.svg" : "assets/post/hearte.png"}
                                                            />
                                                        </Box>
                                                        {/* <Box sx={{ height: "20px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "10px", background: "white", boxShadow: "0px 3px 5px #26262633", width: "40px", borderRadius: "2px" }}>
                                                            <Typography sx={{ fontSize: pxToRem(10) }}>{i?.totalCommentsG ? (i?.totalCommentsG[`${grpData?._id}`] ?? i?.totalComments) : i?.totalComments}</Typography>
                                                        </Box> */}
                                                    </Box>
                                                    {!i?.disable_comments && <Box
                                                        onClick={() => { onPressComment(grpData?._id) }}
                                                        sx={{ height: "20px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "10px", cursor: "pointer", background: "white", boxShadow: "0px 3px 5px #26262633", width: "auto", borderRadius: "2px", padding: "0 10px" }}>
                                                        <Typography sx={{ fontSize: pxToRem(11), fontWeight: 'bold' }}>Comments</Typography>
                                                    </Box>}
                                                </Box>
                                                {likeLine != "No likes yet" && <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14), marginTop: "10px" }}>{likeLine}</Typography>}
                                                {/* {(!i?.disable_comments && !i?.hide_comments) &&
                                                    <Typography
                                                        onClick={() => { onPressComment(grpData?._id) }}
                                                        sx={{ fontSize: pxToRem(14), cursor: "pointer", color: "#7D7D7D" }}>
                                                        COMMENT
                                                    </Typography>} */}
                                                {index != 0 &&
                                                    <Box
                                                        sx={[{ padding: "10px", borderRadius: "8px", cursor: "pointer" }]}
                                                        onClick={() => {
                                                            let a = [...groupName]
                                                            a[index].isHidden = true
                                                            setGroupName([...a])
                                                        }}>
                                                        <Typography color={"primary"} sx={{ fontWeight: "600", fontSize: pxToRem(12), marginTop: "4px", textAlign: "right" }}>- Hide Full Post</Typography>
                                                    </Box>
                                                }
                                                {/* {(!i?.disable_comments && !i?.hide_comments) ?
                                                    <>
                                                        {commentDoc == true ?
                                                            <Box
                                                                component={"img"}
                                                                src={"/assets/post/document.jpeg"}
                                                                sx={{ height: "66px", width: "70px" }}
                                                            /> :
                                                            commentImg == false ?
                                                                <Typography
                                                                    sx={{ fontWeight: "600", fontSize: pxToRem(14), marginBottom: "10px" }}>
                                                                    {i?.lastComment}
                                                                </Typography> :
                                                                <Box
                                                                    component={"img"}
                                                                    src={image_url + i?.lastComment}
                                                                    sx={{ height: "70px", width: "70px" }}
                                                                />
                                                        }
                                                    </> : null} */}
                                            </> :
                                            <>
                                                <Box
                                                    sx={{ display: 'flex', alignItems: "center", justifyContent: "space-around", marginVertical: "16px" }}>
                                                    <Button
                                                        variant="contained"
                                                        sx={{ backgroundColor: theme.palette.success.main, fontSize: pxToRem(13.2), height: "36px", width: "40%" }}
                                                        onClick={() => { setApprovedNow(1); onPressApprove() }}>
                                                        Approve
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        sx={{ backgroundColor: theme.palette.error.main, fontSize: pxToRem(13.2), height: "36px", width: "40%" }}
                                                        onClick={onPressReject}>
                                                        Reject
                                                    </Button>
                                                </Box>
                                                <Box style={{}}>
                                                    {index != 0 &&
                                                        <Box
                                                            sx={[{ padding: "10px", borderRadius: "8px", cursor: "pointer" }]}
                                                            onClick={() => {
                                                                let a = [...groupName]
                                                                a[index].isHidden = true
                                                                setGroupName([...a])
                                                            }}>
                                                            <Typography
                                                                color={"primary"}
                                                                sx={{ fontWeight: "600", fontSize: pxToRem(12), marginTop: "4px", textAlign: "right" }}>
                                                                - Hide Full Post
                                                            </Typography>
                                                        </Box>
                                                    }
                                                </Box>
                                            </>
                                        ) :
                                        <>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Box
                                                        onClick={() => {
                                                            setIsLiked(!isLiked)
                                                            postLiked(i?._id)
                                                        }}
                                                        sx={{ height: "30px", display: "flex", cursor: "pointer", justifyContent: "center", alignItems: "center", background: "white", boxShadow: "0px 3px 5px #26262633", width: "30px", borderRadius: "40px" }}>
                                                        <Box
                                                            component={"img"} sx={{ height: "15px", width: "15px", objectFit: "contain" }}
                                                            src={isLiked ? "assets/post/heart.svg" : "assets/post/hearte.png"}
                                                        />
                                                    </Box>
                                                    {/* <Box sx={{ height: "20px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "10px", background: "white", boxShadow: "0px 3px 5px #26262633", width: "40px", borderRadius: "2px" }}>
                                                        <Typography sx={{ fontSize: pxToRem(10) }}>{i?.totalCommentsG ? (i?.totalCommentsG[`${grpData?._id}`] ?? i?.totalComments) : i?.totalComments}</Typography>
                                                    </Box> */}
                                                </Box>
                                                {!i?.disable_comments && <Box
                                                    onClick={() => { onPressComment(grpData?._id) }}
                                                    sx={{ height: "20px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "10px", cursor: "pointer", background: "white", boxShadow: "0px 3px 5px #26262633", width: "auto", borderRadius: "2px", padding: "0 10px" }}>
                                                    <Typography sx={{ fontSize: pxToRem(11), fontWeight: 'bold' }}>Comments</Typography>
                                                </Box>}
                                            </Box>
                                            <Typography
                                                sx={{ fontWeight: "600", fontSize: pxToRem(14), marginTop: "10px" }}>
                                                {likeLine}
                                            </Typography>
                                            {/* <Typography
                                                onClick={() => { onPressComment(grpData?._id) }}
                                                sx={{ fontSize: pxToRem(14), cursor: "pointer", color: "#7D7D7D" }}>
                                                COMMENT
                                            </Typography> */}
                                            {index != 0 &&
                                                <Box
                                                    sx={[{ padding: "10px", borderRadius: "8px", cursor: "pointer" }]}
                                                    onClick={() => {
                                                        let a = [...groupName]
                                                        a[index].isHidden = true
                                                        setGroupName([...a])
                                                    }}>
                                                    <Typography
                                                        color={"primary"}
                                                        sx={{ fontWeight: "600", fontSize: pxToRem(12), marginTop: "4px", textAlign: "right" }}>
                                                        - Hide Full Post
                                                    </Typography>
                                                </Box>}
                                            {/* {commentDoc == true ?
                                                <Box
                                                    component={"img"}
                                                    src={"/assets/post/document.jpeg"}
                                                    sx={{ height: "66px", width: "70px" }}
                                                /> :
                                                commentImg == false ?
                                                    <Typography sx={{ fontWeight: "600", fontSize: pxToRem(14), marginBottom: "10px" }}>{i?.lastComment}</Typography> :
                                                    <Box component={"img"} src={image_url + i?.lastComment} sx={{ height: "70px", width: "70px" }} />
                                            } */}
                                        </>
                        }
                    </>
                )
            })}
            <PopOverMenu
                anchorEl={popAnchor}
                setAnchorEl={setPopAnchor}
                isDraft={isDraft}
                onPressDelete={() => {
                    onPressDelete(i?._id)
                }}
                onPressEdit={e => {
                    onPressEdit()
                }}
            />
        </Box >
    )
}

const PopOverMenu = ({ anchorEl, setAnchorEl, onPressDelete, isDraft, onPressEdit }) => {
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}>
            <Typography onClick={e => {
                onPressDelete()
            }} sx={{ py: 1, px: 2, fontSize: pxToRem(14), fontWeight: "600", cursor: "pointer" }}>Delete Post</Typography>
            {isDraft &&
                <Typography onClick={e => {
                    onPressEdit()
                }} sx={{ py: 1, px: 2, fontSize: pxToRem(14), fontWeight: "600", cursor: "pointer" }}>Edit Post</Typography>
            }
        </Popover>
    )
}

function MediaSwippers({ allPost, onPressPost, role }) {
    const [currentIndex, setCurrentIndex] = useState(0)
    return (
        <Box position={"relative"}>
            <Carousel onChange={(index) => {
                setCurrentIndex(index)
            }} showIndicators={false} showStatus={false} autoPlay={false} showThumbs={false} dynamicHeight={false}>
                {allPost?.map(item => <div style={{ position: "relative" }}>
                    <Box sx={{ display: "flex", height: "100%", alignItems: "center", background: "white", py: "15px", borderRadius: "5px" }}>
                        {
                            (item?.type == "video") ?
                                (item?.type == "video" && item?.video) ?
                                    (
                                        <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: "100%", marginRight: "2px", height: "400px", marginTop: "16px" }}>
                                            <video controls width="100%" height="100%">
                                                <source src={image_url + item?.video} />
                                                Your browser does not support the video tag.
                                            </video>
                                        </Box>
                                    )
                                    :
                                    (<Box onClick={() => onPressPost(item)} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: "100%", marginRight: "2px", height: "400px", marginTop: "16px" }}>
                                        {item?.type == "video" &&
                                            <Box sx={{ position: 'absolute', zIndex: 10 }}>
                                                <Box component={"img"} src={"/assets/post/play.png"} sx={{ width: "40px", height: "40px", objectFit: "contain" }} />
                                            </Box>
                                        }
                                        <Box component={"img"} src={image_url + item?.img} sx={{ height: "400px", aspectRatio: 1, background: "black", objectFit: "contain", }} />
                                    </Box>)

                                :
                                (<Box onClick={() => onPressPost(item)} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: "100%", marginRight: "2px", height: "400px", marginTop: "16px" }}>
                                    <Box component={"img"} src={image_url + item?.img} sx={{ height: "400px", aspectRatio: 1, background: "black", objectFit: "contain", }} />
                                </Box>)
                        }
                    </Box>
                </div>)}
            </Carousel>
            <PaginationComp total={allPost} currentIndex={currentIndex} />
        </Box>
    )
}

const PaginationComp = ({ currentIndex, total }) => {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {total?.map((x, i) => {
                return (
                    <Box sx={{ height: "10px", width: "10px", marginLeft: i > 0 ? "5px" : "0px", borderRadius: "5px", background: currentIndex == i ? "rgb(16,176,212)" : "#BCE8F1" }} />
                )
            })}
        </Box>
    )
}